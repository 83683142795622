import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import {
  add_receipt_extra_id,
  create_payment_record,
  create_receipt_paid_pdf,
  create_receipt_pdf,
  delete_receipt_extra,
  send_receipt_email,
  send_receipt_payment_email,
  upload_receipt_files,
  view_receipt_paid_pdf,
  view_receipt_pdf,
} from '@/api/receipt-model';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import notification from '@/views/account/settings/pages/notification.vue';
import { create_bank_transaction, create_extra_field, get_extra_month } from '@/api/cerp-model';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import bus from '@/utils/bus';

const router = useRouter();
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
const bus_obj = bus;
export const btn_list = [
  {
    name: t('修改'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return record.receipt_kind == 0 || (record.receipt_kind == 1 && record.payment_status == 0);
    },
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        title: t('修改发票'),
        type: 'update',
        component: 'ReceiptModel',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: t('修改发票信息'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return record.receipt_kind == 0 || (record.receipt_kind == 1 && record.payment_status == 0);
    },
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        title: t('修改发票信息'),
        type: 'update',
        component: 'UpdateReceiptModal',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: t('上传附件'),
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传发票附件'),
        group: '发票',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            upload_receipt_files({
              receipt_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('生成发票PDF'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否生成发票PDF？',
        onOk() {
          return new Promise(resolve => {
            create_receipt_pdf({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('预览发票PDF'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1 && record.nsfile_id != 0;
    },
    cb: (data: any) => {
      view_receipt_pdf({
        org_id: current_org.id,
        receipt_id: data.id,
      }).then((res: any) => {
        if (res && res.file_url) {
          const url: string =
            'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(res.file_url);
          window.open(url, '_blank');
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应PDF'),
          });
        }
      });
    },
  },
  {
    name: t('发送邮件'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      // return record.receipt_kind == 1;
      return record.receipt_kind == 1 && !record.email_record_id;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否发送邮件？',
        onOk() {
          return new Promise(resolve_1 => {
            send_receipt_email({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
                const paymentEditModal = reactive({
                  visible: true,
                  title: t('新建付款记录'),
                  loading: false,
                  form: {
                    settings: {
                      labelAlign: 'right',
                      layout: 'vertical',
                      col: [],
                    },
                    fields: [
                      {
                        type: 'remoteselect',
                        name: 'receipt_id',
                        label: '发票',
                        label_i18n: t('发票'),
                        disabled: true,
                        placeholder: '请选择发票',
                        placeholder_i18n: t('请选择发票'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        labelKeyList: ['receipt_number', 'customer__name'],
                        valueKey: 'id',
                        modalType: 'receipt-query_receipt_list',
                      },
                      {
                        type: 'datepicker',
                        name: 'payment_date',
                        label: '付款日期',
                        label_i18n: t('付款日期'),
                        disabled: false,
                        allowClear: true,
                        inputType: 'number',
                        defaultValue: '',
                        showTime: false,
                        valueFormat: 'YYYY-MM-DD',
                        placeholder: '请填写付款日期',
                        placeholder_i18n: t('请填写付款日期'),
                      },
                      {
                        type: 'input',
                        name: 'amount',
                        label: '总金额',
                        label_i18n: t('总金额'),
                        disabled: false,
                        allowClear: true,
                        inputType: 'number',
                        defaultValue: '',
                        placeholder: '请填写总金额',
                        placeholder_i18n: t('请填写总金额'),
                      },
                      {
                        type: 'remoteselect',
                        name: 'currency_id',
                        labelKeyList: ['code', 'currency'],
                        label: '币种',
                        label_i18n: t('币种'),
                        disabled: false,
                        placeholder: '请选择币种',
                        placeholder_i18n: t('请选择币种'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        modalType: 'baseinfo-country',
                      },
                      {
                        type: 'remoteselect',
                        name: 'cc_bank_account_id',
                        label: 'CC银行账户',
                        label_i18n: t('CC银行账户'),
                        disabled: false,
                        placeholder: '请选择CC银行账户',
                        placeholder_i18n: t('请选择CC银行账户'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        labelKey: 'account_flag',
                        valueKey: 'id',
                        modalType: 'baseinfo-ccbankaccountscc',
                      },
                      {
                        type: 'remoteselect',
                        name: 'transaction_id',
                        label: '银行流水',
                        label_i18n: t('银行流水'),
                        disabled: false,
                        placeholder: '请选择银行流水',
                        placeholder_i18n: t('请选择银行流水'),
                        datasourceType: 'remote',
                        mode: 'default',
                        init_model: { pageSize: 200 },
                        allowClear: true,
                        modalType: 'cerp-banktransaction',
                        cb: () => {
                          const p = new Promise(function (resolve, reject) {
                            const editModal1 = reactive({
                              visible: true,
                              title: t('新建银行流水'),
                              loading: false,
                              form: {
                                settings: {
                                  labelAlign: 'right',
                                  layout: 'vertical',
                                  col: [],
                                },
                                fields: [
                                  {
                                    type: 'input',
                                    name: 'name',
                                    label: '名称',
                                    label_i18n: t('名称'),
                                    disabled: false,
                                    allowClear: true,
                                    inputType: 'text',
                                    defaultValue: '',
                                    placeholder: '请填写名称',
                                    placeholder_i18n: t('请填写名称'),
                                  },
                                  {
                                    type: 'input',
                                    name: 'description',
                                    label: '描述',
                                    label_i18n: t('描述'),
                                    disabled: false,
                                    allowClear: true,
                                    inputType: 'text',
                                    defaultValue: '',
                                    placeholder: '请填写描述',
                                    placeholder_i18n: t('请填写描述'),
                                  },
                                  {
                                    type: 'money',
                                    name: 'amount',
                                    label: '总金额',
                                    label_i18n: t('总金额'),
                                    disabled: false,
                                    allowClear: true,
                                    inputType: 'number',
                                    defaultValue: '',
                                    placeholder: '请填写总金额',
                                    placeholder_i18n: t('请填写总金额'),
                                  },
                                  {
                                    type: 'datepicker',
                                    name: 'date',
                                    label: '收到日期',
                                    label_i18n: t('收到日期'),
                                    disabled: false,
                                    allowClear: true,
                                    inputType: 'number',
                                    defaultValue: '',
                                    showTime: false,
                                    valueFormat: 'YYYY-MM-DD',
                                    placeholder: '请填写收到日期',
                                    placeholder_i18n: t('请填写收到日期'),
                                  },
                                  {
                                    type: 'select',
                                    name: 'transaction_type',
                                    label: '流水种类',
                                    label_i18n: t('流水种类'),
                                    mode: 'default',
                                    disabled: false,
                                    defaultValue: '',
                                    labelKey: 'name',
                                    valueKey: 'value',
                                    placeholder: '请选择流水种类',
                                    placeholder_i18n: t('请选择流水种类'),
                                    datasource: 'transaction_type',
                                  },
                                  {
                                    type: 'remoteselect',
                                    name: 'cc_bank_account_id',
                                    label: 'CC银行账户',
                                    label_i18n: t('CC银行账户'),
                                    disabled: false,
                                    placeholder: '请选择CC银行账户',
                                    placeholder_i18n: t('请选择CC银行账户'),
                                    datasourceType: 'remote',
                                    mode: 'default',
                                    allowClear: true,
                                    labelKey: 'account_flag',
                                    valueKey: 'id',
                                    modalType: 'baseinfo-ccbankaccountscc',
                                  },
                                  {
                                    type: 'remoteselect',
                                    name: 'currency_id',
                                    labelKey: 'currency',
                                    label: '币种',
                                    label_i18n: t('币种'),
                                    default: true,
                                    disabled: false,
                                    placeholder: '请选择薪资币种',
                                    placeholder_i18n: t('请选择薪资币种'),
                                    datasourceType: 'remote',
                                    mode: 'default',
                                    allowClear: true,
                                    modalType: 'baseinfo-country1',
                                  },
                                ],
                                rules: {
                                  name: [{ required: true, message: t('请填输入名称') }],
                                  amount: [{ required: true, message: t('请填输入总金额') }],
                                  transaction_type: [
                                    { required: true, message: t('请选择流水种类') },
                                  ],
                                },
                                model: reactive({
                                  org_id: current_org?.id,
                                  is_active: true,
                                  name: data.receipt_number,
                                  description: null,
                                  amount: data.amount,
                                  currency_id: data.currency_id,
                                  date: moment().format('YYYY-MM-DD'),
                                  recuitment_company_id: null,
                                  transaction_type: '收入',
                                  cc_bank_account_id: data.cc_bank_account_id,
                                }),
                              },
                              options: {
                                transaction_type: [
                                  { value: '收入', name: '收入' },
                                  { value: '支出', name: '支出' },
                                ],
                              },
                              ok: (payload: any) => {
                                return new Promise(resolve1 => {
                                  editModal1.loading = true;
                                  create_bank_transaction(payload)
                                    .then((res: any) => {
                                      message.success(t('创建成功'));
                                      resolve(res.id as number);
                                      resolve1(null);
                                    })
                                    .finally(() => {
                                      editModal1.loading = false;
                                    });
                                });
                              },
                            });
                            modalBox(editModal1);
                          });
                          return p;
                        },
                      },
                    ],
                    rules: {
                      payment_date: [{ required: true, message: t('请填写付款日期') }],
                    },
                    model: reactive({
                      org_id: current_org?.id,
                      receipt_id: data.id,
                      payment_date: moment().format('YYYY-MM-DD'),
                      amount: data.amount,
                      currency_id: data.currency_id,
                      cc_bank_account_id: data.cc_bank_account_id,
                      transaction_id: null,
                    }),
                    watch: {},
                  },
                  options: {},
                  ok: (payload: any) => {
                    return new Promise(resolve => {
                      paymentEditModal.loading = true;
                      create_payment_record({
                        ...payload,
                      })
                        .then(() => {
                          message.success(t('创建成功'));
                          event_obj.emit('refresh');
                          resolve(null);
                        })
                        .finally(() => {
                          paymentEditModal.loading = false;
                        });
                    });
                  },
                });
                if (
                  data.customer__customer_type == 0 &&
                  data.contract__currency_id != null &&
                  data.contract__currency_id == data.currency_id &&
                  data.receipt_kind == 1 &&
                  data.customer__adviser_id &&
                  !data.extra_id &&
                  !data.record_id
                ) {
                  Modal.confirm({
                    title: t('提示'),
                    content: '是否创建增扣项？',
                    okText: t('是'),
                    cancelText: t('否'),
                    onOk() {
                      return new Promise(resolve_2 => {
                        const list: any = ref([]);
                        // 默认工资单
                        get_extra_month({
                          org_id: current_org?.id,
                          adviser_id: data.customer__adviser_id,
                          type: 1,
                          cc_company_id: data.cc_company_id,
                        }).then((res: any) => {
                          if (res.data.length > 0) {
                            list.value = res.data;
                          } else {
                            notification.error({
                              message: t('错误'),
                              description: t('合同无可选月份'),
                            });
                          }
                        });
                        const editModal = reactive({
                          visible: true,
                          title: t('新建增扣项'),
                          loading: false,
                          form: {
                            settings: {
                              labelAlign: 'right',
                              layout: 'vertical',
                              col: [],
                            },
                            fields: [
                              {
                                type: 'input',
                                name: 'adviser__full_name',
                                label: '顾问',
                                label_i18n: t('顾问'),
                                disabled: true,
                                placeholder: '请选择隶属顾问',
                                placeholder_i18n: t('请选择隶属顾问'),
                                datasourceType: 'remote',
                                allowClear: false,
                              },
                              {
                                type: 'radio',
                                name: 'type',
                                label: '类型',
                                label_i18n: t('类型'),
                                mode: 'default',
                                disabled: false,
                                defaultValue: '',
                                labelKey: 'name',
                                valueKey: 'value',
                                datasource: 'type',
                              },
                              {
                                type: 'remoteselect',
                                name: 'cc_company_id',
                                label: 'CC公司',
                                label_i18n: t('CC公司'),
                                disabled: false,
                                placeholder: '请选择CC公司',
                                placeholder_i18n: t('请选择CC公司'),
                                datasourceType: 'remote',
                                allowClear: true,
                                mode: 'default',
                                modalType: 'baseinfo-cccompany',
                              },
                              {
                                type: 'radio',
                                name: 'type1',
                                label: '增扣类型',
                                label_i18n: t('增扣类型'),
                                mode: 'default',
                                disabled: false,
                                defaultValue: '',
                                labelKey: 'name',
                                valueKey: 'value',
                                datasource: 'type1',
                              },
                              {
                                type: 'input',
                                name: 'custom_field_name',
                                label: '自定义项描述',
                                label_i18n: t('自定义项描述'),
                                disabled: false,
                                allowClear: true,
                                inputType: 'text',
                                defaultValue: '',
                                placeholder: '请填写自定义项描述',
                                placeholder_i18n: t('请填写自定义项描述'),
                              },
                              {
                                type: 'input',
                                name: 'custom_field_value',
                                label: '自定义项值',
                                label_i18n: t('自定义项值'),
                                disabled: false,
                                allowClear: true,
                                inputType: 'text',
                                defaultValue: '',
                                placeholder: '请填写自定义项值',
                                placeholder_i18n: t('请填写自定义项值'),
                              },
                              {
                                type: 'input',
                                name: 'content',
                                label: '内容',
                                label_i18n: t('内容'),
                                disabled: false,
                                allowClear: true,
                                inputType: 'text',
                                defaultValue: '',
                                placeholder: '请填写内容',
                                placeholder_i18n: t('请填写内容'),
                              },
                              {
                                type: 'monthpicker',
                                name: 'month',
                                label: '月份',
                                label_i18n: t('月份'),
                                disabled: false,
                                allowClear: true,
                                inputType: 'number',
                                defaultValue: '',
                                showTime: false,
                                valueFormat: 'YYYY-MM-DD',
                                format: 'YYYY-MM',
                                placeholder: '请选择月份',
                                placeholder_i18n: t('请选择月份'),
                                disabledTime: (date: any) => {
                                  return list.value.indexOf(date.format('YYYY-MM')) < 0;
                                },
                              },
                              {
                                type: 'textarea',
                                name: 'note',
                                label: '备注',
                                label_i18n: t('备注'),
                                disabled: false,
                                allowClear: true,
                                inputType: 'text',
                                maxlength: 200,
                                defaultValue: '',
                                placeholder: '请填写备注',
                                placeholder_i18n: t('请填写备注'),
                              },
                            ],
                            rules: {
                              type: [{ required: true, message: t('请选择类型') }],
                              type1: [{ required: true, message: t('请选择增扣类型') }],
                              custom_field_name: [
                                { required: true, message: t('请填写自定义项描述') },
                              ],
                              custom_field_value: [
                                { required: true, message: t('请填写自定义项值') },
                              ],
                              month: [{ required: true, message: t('请选择月份') }],
                              cc_company_id: [{ required: true, message: t('请选择CC公司') }],
                            },
                            model: reactive({
                              org_id: current_org?.id,
                              is_active: true,
                              adviser_id: data.customer__adviser_id,
                              adviser__full_name: data.customer__adviser__full_name,
                              custom_field_name: null,
                              custom_field_value: -data.amount,
                              is_finished: false,
                              content: null,
                              note: null,
                              type: 1,
                              type1: 0,
                              month: data.service_month,
                              cc_company_id: data.cc_company_id,
                            }),
                            watch: {
                              adviser_id: (data: any) => {
                                if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                                  get_extra_month({
                                    org_id: current_org?.id,
                                    adviser_id: data.adviser_id,
                                    type: data.type,
                                    cc_company_id: data.cc_company_id,
                                  }).then((res: any) => {
                                    if (res.data.length > 0) {
                                      list.value = res.data;
                                    } else {
                                      notification.error({
                                        message: t('错误'),
                                        description: t('合同无可选月份'),
                                      });
                                    }
                                  });
                                }
                              },
                              type: (data: any) => {
                                if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                                  get_extra_month({
                                    org_id: current_org?.id,
                                    adviser_id: data.adviser_id,
                                    type: data.type,
                                    cc_company_id: data.cc_company_id,
                                  }).then((res: any) => {
                                    if (res.data.length > 0) {
                                      list.value = res.data;
                                    } else {
                                      notification.error({
                                        message: t('错误'),
                                        description: t('合同无可选月份'),
                                      });
                                    }
                                  });
                                }
                              },
                              cc_company_id: (data: any) => {
                                if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                                  get_extra_month({
                                    org_id: current_org?.id,
                                    adviser_id: data.adviser_id,
                                    type: data.type,
                                    cc_company_id: data.cc_company_id,
                                  }).then((res: any) => {
                                    if (res.data.length > 0) {
                                      list.value = res.data;
                                    } else {
                                      notification.error({
                                        message: t('错误'),
                                        description: t('合同无可选月份'),
                                      });
                                    }
                                  });
                                }
                              },
                              type1: (data: any) => {
                                if (data.type1) {
                                  if (data.custom_field_value < 0) {
                                    data.custom_field_value = -data.custom_field_value;
                                  }
                                } else {
                                  if (data.custom_field_value > 0) {
                                    data.custom_field_value = -data.custom_field_value;
                                  }
                                }
                              },
                            },
                          },
                          options: {
                            type: [
                              { value: 0, name: t('请款单') },
                              { value: 1, name: t('工资单') },
                            ],
                            type1: [
                              { value: 0, name: t('扣减') },
                              { value: 1, name: t('增补') },
                            ],
                          },
                          ok: (payload: any) => {
                            return new Promise(resolve => {
                              editModal.loading = true;
                              create_extra_field({
                                ...payload,
                              })
                                .then((res: any) => {
                                  add_receipt_extra_id({
                                    org_id: current_org?.id,
                                    receipt_id: data.id,
                                    extra_id: res.id,
                                  }).then(() => {
                                    event_obj.emit('refresh');
                                  });
                                  bus.emit('onCustomEvent', { type: 'get_extra_field_num' });
                                  message.success(t('创建成功'));
                                  resolve(null);
                                })
                                .finally(() => {
                                  editModal.loading = false;
                                });
                            });
                          },
                          cancel: () => {},
                        });
                        modalBox(editModal);
                        resolve_2(null);
                      });
                    },
                    onCancel() {
                      Modal.confirm({
                        title: t('提示'),
                        content: '是否直接付款？',
                        okText: t('是'),
                        cancelText: t('否'),
                        onOk() {
                          return new Promise(resolve => {
                            modalBox(paymentEditModal);
                            resolve(null);
                          });
                        },
                        onCancel() {},
                      });
                    },
                  });
                } else if (!data.extra_id && !data.record_id) {
                  Modal.confirm({
                    title: t('提示'),
                    content: '是否直接付款？',
                    okText: t('是'),
                    cancelText: t('否'),
                    onOk() {
                      return new Promise(resolve => {
                        modalBox(paymentEditModal);
                        resolve(null);
                      });
                    },
                    onCancel() {},
                  });
                }
              })
              .finally(() => {
                resolve_1(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('重新发送邮件'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1 && record.email_record_id;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否重新发送邮件？',
        onOk() {
          return new Promise(resolve_1 => {
            send_receipt_email({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve_1(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('新建增扣项'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return (
        record.customer__customer_type == 0 &&
        record.receipt_kind == 1 &&
        record.contract__currency_id != null &&
        record.contract__currency_id == record.currency_id &&
        record.customer__adviser_id &&
        !record.extra_id &&
        !record.record_id
      );
    },
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = ref([]);
      // 默认工资单
       get_extra_month({
         org_id: current_org?.id,
         adviser_id: record.customer__adviser_id,
         type: 1,
         cc_company_id: record.cc_company_id,
       }).then((res: any) => {
         if (res.data.length > 0) {
           list.value = res.data;
         } else {
           notification.error({
             message: t('错误'),
             description: t('合同无可选月份'),
           });
         }
       });
      const editModal = reactive({
        visible: true,
        title: t('新建增扣项'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: t('顾问'),
              disabled: true,
              placeholder: '请选择隶属顾问',
              placeholder_i18n: t('请选择隶属顾问'),
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'radio',
              name: 'type',
              label: '类型',
              label_i18n: t('类型'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: false,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              allowClear: true,
              mode: 'default',
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'radio',
              name: 'type1',
              label: '增扣类型',
              label_i18n: t('增扣类型'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type1',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: t('自定义项描述'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
              placeholder_i18n: t('请填写自定义项描述'),
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: t('自定义项值'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
              placeholder_i18n: t('请填写自定义项值'),
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: t('内容'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
              placeholder_i18n: t('请填写内容'),
            },
            {
              type: 'monthpicker',
              name: 'month',
              label: '月份',
              label_i18n: t('月份'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请选择月份',
              placeholder_i18n: t('请选择月份'),
              disabledTime: (date: any) => {
                return list.value.indexOf(date.format('YYYY-MM')) < 0;
              },
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: t('备注'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
              placeholder_i18n: t('请填写备注'),
            },
          ],
          rules: {
            type: [{ required: true, message: t('请选择类型') }],
            type1: [{ required: true, message: t('请选择增扣类型') }],
            custom_field_name: [{ required: true, message: t('请填写自定义项描述') }],
            custom_field_value: [{ required: true, message: t('请填写自定义项值') }],
            month: [{ required: true, message: t('请选择月份') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.customer__adviser_id,
            adviser__full_name: record.customer__adviser__full_name,
            custom_field_name: null,
            custom_field_value: -record.amount,
            is_finished: false,
            content: null,
            note: null,
            type: 1,
            type1: 0,
            month: record.service_month,
            cc_company_id: record.cc_company_id,
          }),
          watch: {
            adviser_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            type: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            cc_company_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            type1: (data: any) => {
              if (data.type1) {
                if (data.custom_field_value < 0) {
                  data.custom_field_value = -data.custom_field_value;
                }
              } else {
                if (data.custom_field_value > 0) {
                  data.custom_field_value = -data.custom_field_value;
                }
              }
            },
          },
        },
        options: {
          type: [
            { value: 0, name: t('请款单') },
            { value: 1, name: t('工资单') },
          ],
          type1: [
            { value: 0, name: t('扣减') },
            { value: 1, name: t('增补') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_extra_field({
              ...payload,
            })
              .then((res: any) => {
                add_receipt_extra_id({
                  org_id: current_org?.id,
                  receipt_id: record.id,
                  extra_id: res.id,
                }).then(() => {
                  event_obj.emit('refresh');
                });
                bus.emit('onCustomEvent', { type: 'get_extra_field_num' });
                message.success(t('创建成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('删除增扣项'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.extra_id && !record.extra__is_finished;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否删除增扣项？',
        onOk() {
          return new Promise(resolve => {
            delete_receipt_extra({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
                bus.emit('onCustomEvent', { type: 'get_extra_field_num' });
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('新建付款记录'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return !record.record_id && !record.extra_id;
    },
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新建付款记录'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'receipt_id',
              label: '发票',
              label_i18n: t('发票'),
              disabled: true,
              placeholder: '请选择发票',
              placeholder_i18n: t('请选择发票'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKeyList: ['receipt_number', 'customer__name'],
              valueKey: 'id',
              modalType: 'receipt-query_receipt_list',
            },
            {
              type: 'datepicker',
              name: 'payment_date',
              label: '付款日期',
              label_i18n: t('付款日期'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写付款日期',
              placeholder_i18n: t('请填写付款日期'),
            },
            {
              type: 'input',
              name: 'amount',
              label: '总金额',
              label_i18n: t('总金额'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
              placeholder_i18n: t('请填写总金额'),
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择币种',
              placeholder_i18n: t('请选择币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              placeholder_i18n: t('请选择CC银行账户'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'transaction_id',
              label: '银行流水',
              label_i18n: t('银行流水'),
              disabled: false,
              placeholder: '请选择银行流水',
              placeholder_i18n: t('请选择银行流水'),
              datasourceType: 'remote',
              mode: 'default',
              init_model: { pageSize: 200 },
              allowClear: true,
              modalType: 'cerp-banktransaction',
              cb: () => {
                const p = new Promise(function (resolve, reject) {
                  const editModal1 = reactive({
                    visible: true,
                    title: t('新建银行流水'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'name',
                          label: '名称',
                          label_i18n: t('名称'),
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写名称',
                          placeholder_i18n: t('请填写名称'),
                        },
                        {
                          type: 'input',
                          name: 'description',
                          label: '描述',
                          label_i18n: t('描述'),
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写描述',
                          placeholder_i18n: t('请填写描述'),
                        },
                        {
                          type: 'money',
                          name: 'amount',
                          label: '总金额',
                          label_i18n: t('总金额'),
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          placeholder: '请填写总金额',
                          placeholder_i18n: t('请填写总金额'),
                        },
                        {
                          type: 'datepicker',
                          name: 'date',
                          label: '收到日期',
                          label_i18n: t('收到日期'),
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写收到日期',
                          placeholder_i18n: t('请填写收到日期'),
                        },
                        {
                          type: 'select',
                          name: 'transaction_type',
                          label: '流水种类',
                          label_i18n: t('流水种类'),
                          mode: 'default',
                          disabled: false,
                          defaultValue: '',
                          labelKey: 'name',
                          valueKey: 'value',
                          placeholder: '请选择流水种类',
                          placeholder_i18n: t('请选择流水种类'),
                          datasource: 'transaction_type',
                        },
                        {
                          type: 'remoteselect',
                          name: 'cc_bank_account_id',
                          label: 'CC银行账户',
                          label_i18n: t('CC银行账户'),
                          disabled: false,
                          placeholder: '请选择CC银行账户',
                          placeholder_i18n: t('请选择CC银行账户'),
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'account_flag',
                          valueKey: 'id',
                          modalType: 'baseinfo-ccbankaccountscc',
                        },
                        {
                          type: 'remoteselect',
                          name: 'currency_id',
                          labelKey: 'currency',
                          label: '币种',
                          label_i18n: t('币种'),
                          default: true,
                          disabled: false,
                          placeholder: '请选择薪资币种',
                          placeholder_i18n: t('请选择薪资币种'),
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          modalType: 'baseinfo-country1',
                        },
                      ],
                      rules: {
                        name: [{ required: true, message: t('请填输入名称') }],
                        amount: [{ required: true, message: t('请填输入总金额') }],
                        transaction_type: [{ required: true, message: t('请选择流水种类') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        name: record.receipt_number,
                        description: null,
                        amount: record.amount,
                        currency_id: record.currency_id,
                        date: moment().format('YYYY-MM-DD'),
                        recuitment_company_id: null,
                        transaction_type: '收入',
                        cc_bank_account_id: record.cc_bank_account_id,
                      }),
                    },
                    options: {
                      transaction_type: [
                        { value: '收入', name: '收入' },
                        { value: '支出', name: '支出' },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve1 => {
                        editModal1.loading = true;
                        create_bank_transaction(payload)
                          .then((res: any) => {
                            message.success(t('创建成功'));
                            resolve(res.id as number);
                            resolve1(null);
                          })
                          .finally(() => {
                            editModal1.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal1);
                });
                return p;
              },
            },
          ],
          rules: {
            payment_date: [{ required: true, message: t('请填写付款日期') }],
          },
          model: reactive({
            org_id: current_org?.id,
            receipt_id: record.id,
            payment_date: moment().format('YYYY-MM-DD'),
            amount: record.amount,
            currency_id: record.currency_id,
            cc_bank_account_id: record.cc_bank_account_id,
            transaction_id: null,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_payment_record({
              ...payload,
            })
              .then(() => {
                message.success(t('创建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('生成付款凭证'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1 && record.payment_date != null && record.nsfile_id != 0;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否生成付款凭证？',
        onOk() {
          return new Promise(resolve => {
            create_receipt_paid_pdf({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('预览付款凭证'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1 && record.paid_nsfile_id != 0;
    },
    cb: (data: any) => {
      view_receipt_paid_pdf({
        org_id: current_org.id,
        receipt_id: data.id,
      }).then((res: any) => {
        if (res && res.file_url) {
          const url: string =
            'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(res.file_url);
          window.open(url, '_blank');
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应PDF'),
          });
        }
      });
    },
  },
  {
    name: t('发送付款凭证邮件'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.receipt_kind == 1 && record.paid_nsfile_id != 0;
    },
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否发送邮件？',
        onOk() {
          return new Promise(resolve => {
            send_receipt_payment_email({ org_id: current_org.id, receipt_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('新建发票'),
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        title: t('新建发票'),
        component: 'ReceiptModel',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
        modifyModal.visible = false;
      };
      modifyModal.cancel = () => {
        event_obj.emit('refresh');
        modifyModal.visible = false;
      };
      modalBox(modifyModal);
    },
  },
];
